<template>
  <article class="checkByTeacher" v-loading="loading">
    <header class="header">
      <h1>按教师查看</h1>
      <div class="row">
        <div class="left">
          <div class="search-input">
            <el-input
                placeholder="请输入教师手机号搜索"
                v-model="searchText">
              :clearable="true"
            </el-input>
            <div class="icon"></div>
          </div>
          <DatePicker v-model="timeRang" :time-interval="180" format="yyyy-MM-dd" value-format="yyyy-MM-dd" />
          <el-button class="search-btn" type="primary" plain @click="selectByPhone">查询</el-button>
        </div>
        <div class="right">
          <div v-if="selection.length > 0" class="right-btn delete" @click="batchDelete">删除所选</div>
          <div class="right-btn export" @click="batchExport">批量导出</div>
        </div>
      </div>
    </header>

    <div class="column">
      <div class="left">
        <el-select class="select-status" v-model="clazzId" @change="clazzChange">
          <el-option v-for="item in clazz" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-select class="select-status" v-model="status" @change="statusChange">
          <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="right">
        <div class="tips" v-show="schoolName">正在查询：{{ schoolName }} {{ teacherName }}：{{ teacherPhone }}</div>
      </div>
    </div>
    <section class="content">
      <BagList
          :list="list"
          :show-check="true"
          @exportExcel="exportExcel"
          @delWork="delWork"
          @bagWorkList="bagWorkList"
          @setWork="setWork"
          @selectionChange="selectionChange"
      ></BagList>
      <div class="text-right mt-20 pr-60">
        <Pagination :page-size="pageSize" :total="total" :page="page" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </section>
    <EditWorkSet ref="EditWorkSet" @refreshPage="refreshPage"></EditWorkSet>
  </article>
</template>

<script>
import BagList from './components/BagList.vue'
import EditWorkSet  from '@/components/EditWorkSet'
import DatePicker  from '@/components/common/DatePicker'
import Pagination from '@/components/common/Pagination.vue'
import {batchDeleteHomeworkBag, exportBagByCreator, exportBagByTeacher, findBagPageByTeacher} from '@/api/homework.js'
import dayjs from 'dayjs'

export default {
  name: 'checkByTeacher',
  components: {
    BagList,
    EditWorkSet,
    DatePicker,
    Pagination
  },
  data() {
    return {
      loading: false,
      searchText: '',
      teacherId: '',
      schoolName: '',
      teacherName: '',
      teacherPhone: '',
      clazz: [{
        'id': '',
        'name': '全部班级'
      }],
      clazzId: '',
      statusList: [
        {
          'id': '',
          'name': '全部状态'
        },
        {
          'id': '0',
          'name': '未开始'
        },
        {
          'id': '1',
          'name': '进行中'
        },
        {
          'id': '2',
          'name': '已结束'
        }
      ],
      status: '',
      list: [],
      selection: [],
      page: 1,
      pageSize: 10,
      total: 1,
      timeRang: [],
      startTime: '',
      endTime: '',
      schoolSection: '',
      schoolProvinceId: ''
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  created() {
    this.startTime = dayjs().subtract(1, 'month').format('YYYY-MM-DD 00:00:00')
    this.endTime = dayjs().format('YYYY-MM-DD 23:59:59')
    this.timeRang = [dayjs(this.startTime).format('YYYY-MM-DD'), dayjs(this.endTime).format('YYYY-MM-DD')]
  },
  activated() {
    if (this.common.globalData.checkByTeacherRefresh) {
      this.common.globalData.checkByTeacherRefresh = false
      this.findBagList()
    }
  },
  watch: {
    timeRang: {
      deep:true,
      handler: function (val) {
        this.startTime = val[0] ? dayjs(val[0]).format('YYYY-MM-DD 00:00:00') : ''
        this.endTime = val[1] ? dayjs(val[1]).format('YYYY-MM-DD 23:59:59') : ''
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.page = val
      this.findBagList()
    },
    handleCurrentChange(val) {
      this.page = val
      this.findBagList()
    },
    bagWorkList(index) {
      const {list} = this
      const {id, name, taskType} = list[index]
      if (taskType === 3) {
        this.$confirm('该内容Web版未适配，请前往APP查看', '提示', {
          type: 'warning'
        }).then(() => {})
        return
      }
      this.$router.push({
        name: 'bagWorkList',
        query: {
          id,
          name,
          schoolSection: this.schoolSection,
          schoolProvinceId: this.schoolProvinceId
        }
      })
    },
    exportExcel(itemId) {
      const that = this
      const h = this.$createElement
      let checked = false
      const random = Math.random()
      this.$confirm('确认导出成绩吗？', '提示', {
        message: h('div', null, [
          h('p', null, '确认导出成绩吗？'),
          h('ElCheckbox', {
            style: 'position: absolute;left: 6px;bottom: -30px;',
            key: random,
            props: {
              checked: checked
            },
            on: {
              change(val) {
                checked = val
              }
            }
          }, '导出转换为20分制')
        ]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // const url = `${that.$urls.exportBagScore}?bagId=${itemId}`
        // window.open(url)
        const convertScore = checked ? 1 : 0
        checked = false
        that.$axios({
          method: 'post',
          url: that.$urls.exportBagScope,
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            bagId: itemId,
            convertScore
          }
        }).then((response) => {
          if (response.state === '11') {
            that.$message.success('下载任务已提交，请前往下载中心查看进度')
          }
        }).catch(() => {
        })
      }).catch((err) => {
        checked = false
        throw err
      })
    },
    // 批量导出
    batchExport() {
      const that = this
      const { startTime, endTime, total, userInfo, status, clazzId } = this
      if (total > 200) {
        this.$message({
          type: 'warning',
          message: '批量导出作业数量不能超过200个'
        })
        return
      }
      const h = this.$createElement
      let checked = false
      const random = Math.random()
      this.$confirm('确认批量导出作业包？', '提示', {
        message: h('div', null, [
          h('p', null, '确认导出成绩吗？'),
          h('ElCheckbox', {
            style: 'position: absolute;left: 6px;bottom: -30px;',
            key: random,
            props: {
              checked: checked
            },
            on: {
              change(val) {
                checked = val
              }
            }
          }, '导出转换为20分制')
        ]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const convertScore = checked ? 1 : 0
        checked = false
        const dataParam = {
          teacherId: userInfo.teacherId,
          startTime,
          endTime,
          convertScore,
          status,
          clazzId
        }
        that.loading = true
        exportBagByTeacher(dataParam).then(response => {
          that.loading = false
          if (response.state === '11') {
            that.$message.success('下载任务已提交，请前往下载中心查看进度')
          }
        }).catch(() => {
          that.loading = false
        })
      }).catch((err) => {
        throw err
      })
    },
    setDate(index, date) {
      const {list} = this
      const {id} = list[index]
      date = this.common.binarySystem(date)
      date = this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(date))
      this.$axios({
        method: 'post',
        url: this.$urls.homeworkUpdateItem,
        data: {
          itemId: id,
          dateEnd: date
        }
      })
          .then((response) => {
            if (response.state === '11') {
              this.$message({
                type: 'success',
                message: '修改成功'
              })
              const time = this.common.formatDate('MM-dd hh:mm', new Date(date))
              this.$set(this.list[index], 'showDateEnd', time)
            }
          })
    },
    delWork(index) {
      const that = this
      this.$confirm('删除后学生将无法收到该作业？', '删除作业', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            that.confirmDel(index)
          })
          .catch((err) => {
            throw err
          })
    },
    confirmDel(index) {
      const {list} = this
      const {id} = list[index]
      let url
      const dataParam = {}
      url = this.$urls.deleteHomeBag
      dataParam.bagId = id
      this.$axios({
        method: 'post',
        url,
        data: dataParam
      })
          .then((response) => {
            if (response.state === '11') {
              this.list.splice(index, 1)
            }
          })
    },

    // 批量删除
    batchDelete() {
      const that = this
      const { userInfo, selection } = this
      this.$confirm('删除后学生将无法收到所选作业？', '删除作业', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          homeworkBagIds: selection.map(el => el.id),
          teacherId: userInfo.teacherId
        }
        that.loading = true
        batchDeleteHomeworkBag(params).then(response => {
          that.loading = false
          if (response.state === '11') {
            this.findBagList()
          }
        })
      }).catch((err) => {
        throw err
      })
    },
    clazzChange() {
      this.page = 1
      this.findBagList()
    },
    statusChange() {
      this.page = 1
      this.findBagList()
    },
    selectByPhone() {
      this.selection = []
      const {searchText, startTime} = this
      if (!searchText) {
        this.$alert('请输入手机号', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      if (!startTime) {
        this.$message.error('开始时间不能为空')
        return
      }
      this.schoolName = ''
      this.teacherName = ''
      this.teacherId = ''
      this.teacherPhone = ''
      this.clazz = []
      this.clazzId = ''
      this.list = []
      this.$axios({
        method: 'post',
        url: this.$urls.selectByPhone,
        data: {
          phone: searchText
        }
      })
          .then((response) => {
            this.loading = false
            if (response.state === '11') {
              const {data} = response
              this.schoolName = data.schoolName
              this.teacherName = data.name
              this.teacherId = data.id
              this.teacherPhone = data.phone
              this.schoolSection = data.schoolSection
              this.schoolProvinceId = data.schoolProvinceId
              this.findListByTeacher()
              this.findBagList()
            }
          })
          .catch(() => {
            this.loading = false
          })
    },
    findListByTeacher() {
      console.log(this.teacherId)
      this.$axios({
        method: 'post',
        url: this.$urls.findListByTeacher,
        data: {
          teacher: this.teacherId
        }
      })
          .then((response) => {
            this.loading = false
            if (response.state === '11') {
              const {data} = response
              const obj = {
                'id': '',
                'name': '全部班级'
              }
              this.clazz = [obj, ...data]
            }
          })
          .catch(() => {
            this.loading = false
          })
    },
    findBagList() {
      this.selection = []
      const { page, pageSize, startTime, endTime } = this
      if (!startTime) {
        this.$message.error('开始时间不能为空')
        return
      }
      console.log('findBagList', this.teacherId)
      const dataParam = {
        teacherId: this.teacherId,
        page,
        pageSize,
        startTime,
        endTime
      }
      const {status} = this
      if (status !== '') dataParam.status = status
      const {clazzId} = this
      if (clazzId !== '') dataParam.clazzId = clazzId
      this.loading = true
      findBagPageByTeacher(dataParam)
          .then((response) => {
            this.loading = false
            if (response.state === '11') {
              const {data} = response
              const { pageContents, total } = data
              this.total = total
              for (let i = 0, len = pageContents.length; i < len; i += 1) {
                let {dateStart} = pageContents[i]
                const arr = dateStart.split('T')
                pageContents[i].dateStart = arr[0]
              }
              this.list = pageContents
              this.$store.commit('includeAdd', 'checkByTeacher')
            }
          })
          .catch(() => {
            this.loading = false
          })
    },
    // 设置作业包
    setWork(item) {
      this.$refs.EditWorkSet.dialogVisibleChange(item)
    },
    selectionChange(selection) {
      this.selection = selection
    },
    // 更新数据
    refreshPage() {
      this.findBagList()
    }
  }
}
</script>

<style lang="scss">
.checkByTeacher {
  .search-input {
    input {
      background-color: #f6f6f6;
      height: 36px;
      padding-left: 36px;
    }
  }
}
</style>
<style lang="scss" scoped>
.header {
  @include flex(space-between, flex-start);
  flex-direction: column;
  background-color: #fff;
  height: 126px;
  padding: 0 28px 10px;
  margin-bottom: 10px;

  h1 {
    height: 84px;
    line-height: 84px;
    font-size: 20px;
  }

  .row {
    @include flex(space-between);
    width: 100%;
    font-size: 15px;

    .left {
      @include flex;

      .search-input {
        position: relative;
        margin-right: 14px;

        .icon {
          background: url("../../assets/search.png") no-repeat center center;
          background-size: 16px 16px;
          width: 36px;
          height: 36px;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      .search-btn {
        background: linear-gradient(to right, #6DBBFC, #309AF2);
        width: 100px;
        margin-left: 14px;
        color: #fff;
        font-size: 15px;
        border-color: transparent;
      }
    }

    .right{
      @include flex;
      .right-btn{
        height: 36px;
        font-size: 15px;
        line-height: 36px;
        border: 1px solid #309AF2;
        color: #309AF2;
        border-radius: 8px;
        background: #FFFFFF;
        padding: 0 14px;
        cursor: pointer;
        &.export{
          border-color: #309AF2;
          color: #309AF2;
        }
        &.delete{
          border-color: #FF3C30;
          color: #FF3C30;
        }
        &+.right-btn{
          margin-left: 14px;
        }
      }
    }
  }
}

.column {
  @include flex(space-between);
  background-color: #fff;
  height: 52px;
  padding: 0 28px;
  .left{
    @include flex;
    .select-status{
      width: 140px;
      height: 32px;
      ::v-deep .el-input{
        .el-input__inner{
          color: #333333;
          font-size: 14px;
        }
        .el-input__suffix{
          .el-input__suffix-inner{
            .el-select__caret{
              &::before{
                color: #333333;
                font-weight: 900;
              }
            }
          }
        }
      }
      &+.select-status{
        margin-left: 14px;
      }
    }
  }
  .right{
    @include flex;
    .tips {
      font-weight: 400;
      font-size: 15px;
      color: #999999;
      line-height: 21px;
      text-align: right;
    }
  }
}

.content {
  background-color: #fff;
  //height: calc(100vh - 268px);
  //overflow: auto;
  padding: 0 28px 10px;

}
</style>
